// Here you can add other styles
body {
  // background-repeat: repeat;
  // background: $bg-body;
  font-family: $font-family;
  color: $font-color;
  // font-size: $base-font;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family;
}
p {
  font-family: $font-family;
}
span {
  font-family: $font-family;
}
label {
  font-family: $font-family;
}
.text-right {
  text-align: right;
}
.ml-auto {
  margin-left: auto;
}
.sidebarDesignSet {
  box-shadow: 6px -24px 22px rgba(0, 0, 0, 0.06);
  .sidebarLogoImg {
    background: #fff !important;
  }
  .sidebarLogoContent {
    background: #fff !important;
    .nav-item {
      border-bottom: 1px solid #ebecec;
      &:first-child {
        border-top: 1px solid #ebecec;
      }
      .nav-link {
        color: #1f3361;
      }
    }
  }
}
.sliderDesign {
  margin-bottom: 10px;
  .progress {
    height: 8px;
    border-radius: 62px;
    margin-top: 8px;
    .progress-bar {
      background-color: #ff891c;
      border-radius: 62px;
    }
  }
}
.sliderDesignDashboard {
  margin-bottom: 10px;
  .progress {
    height: 8px;
    border-radius: 62px;
    margin-top: 8px;
    background: #6a6e78;
    .progress-bar {
      background-color: #ff891c;
      border-radius: 62px;
    }
  }
}
.otherCompaignDesignDashboard {
  margin-bottom: 10px;
  .progress {
    height: 7px;
    border-radius: 62px;
    margin-top: 8px;
    background: #dadee8;
    .progress-bar {
      background-color: #27ae60;
      border-radius: 62px;
    }
  }
}
.investorNavTabs {
  border-bottom: 3px solid rgba(31, 51, 97, 0.15);
  margin-bottom: 30px;
  margin-top: 50px;
  height: 41px;
  &.myPortfolioTabs {
    margin-top: 0px;
    height: 42px;
    .nav-item {
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        margin-left: 0 !important;
      }
    }
  }
  &.nftListing {
    border: none;
    height: 34px;
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin-top: 36px;
  }
  .nav-link {
    transition: none !important;
    &:hover {
      border-color: transparent !important;
    }
    &.active {
      background: #fafbff;
    }
  }
  .nav-item {
    margin-left: 27px;
    border: none !important;
    cursor: pointer;
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      margin-left: 12px !important;
    }
    .active {
      border: none !important;
      border-bottom: 3px solid rgba(31, 51, 97, 0.7) !important;
      p {
        color: #1f3361;
      }
    }
    &:focus-within {
      border: none !important;
    }
    &:hover {
      border: none !important;
    }
  }
}
.investorReportsAccordionDesign {
  .accordion-item {
    border-radius: 10px !important;
    border: 1px solid rgba(31, 51, 97, 0.15) !important;
    margin-bottom: 30px;
    .accordion-button {
      display: inherit;
      background: #ffffff;
      border-radius: 10px !important;
      padding: 13px 16px;
      box-shadow: none !important;
      &::after {
        margin-left: auto;
        float: right;
        position: absolute;
        right: 20px;
        top: 18px;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .accordion-body {
      border-top: 1px solid rgba(31, 51, 97, 0.15) !important;
      padding: 20px 16px 15px;
    }
  }
}
.successfullProgressbar {
  width: 334px;
  margin-left: auto;
  margin-right: auto;
  height: 8px;
  background: #fff;
  border: 0.1px solid rgba(31, 51, 97, 0.1);
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 273px;
  }
}
.orderReciptDesign {
  .modal-dialog {
    width: 400px;
    @media only screen and (min-width: 320px) and (max-width: 480px) {
      width: 343px;
    }
    .modal-content {
      border-radius: 14px;
    }
  }
}

// menu css
.header-nav {
  .rightSideNav {
    display: flex;
    a {
      padding: 22px 14px !important;
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        padding: 22px 9px !important;
      }
    }
    .dropDownDesign {
      .dropdown-menu.show {
        box-shadow: -4px 0px 16px 2px rgba(0, 0, 0, 0.06);
        border: none;
        border-radius: 6px !important;
        padding: 0px;
        .dropdown-header {
          background-color: #ebf4ff !important;
          font-size: 16px;
          line-height: 19px;
          color: #001661;
          padding: 8px 10px !important;
        }
        .dropdown-divider {
          margin: 0px;
          background: rgba(31, 51, 97, 0.2);
          height: 1px;
        }
        .dropdown-item {
          padding: 10px 9px !important;
          font-size: 14px;
          color: #1f3361 !important;
          &:hover {
            background-color: #ebf4ff;
          }
        }
      }
    }
  }
}
.tableDesign {
  thead {
    border-bottom: 1px solid rgba(31, 51, 97, 0.1);
    th {
      font-size: 12px;
      padding: 12px 20px;
      color: rgba(31, 51, 97, 0.6);
    }
  }
  tbody {
    td {
      font-size: 14px;
      padding: 12px 20px;
      color: #1f3361;
      .sellButtonDesign {
        background: transparent;
        border-color: #1652f0;
        color: #1652f0;
        width: 53px;
        padding: 0;
        height: 30px;
        border-radius: 2px;
        &:hover {
          background: #1652f0;
          border-color: #1652f0;
          color: #fff;
        }
      }
    }
  }
  .nav-item {
    .nav-link {
      &.active {
        color: #1652f0;
        border-bottom: 3px solid #1652f0;
      }
    }
  }
}

.nftPopupGellary {
  width: 700px;
  position: relative;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 280px !important;
  }
  img {
    width: 100%;
    border-radius: 12px;
    margin-bottom: 10px;
  }
  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: auto;
  }
}
.nftPopup {
  width: 500px;
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 280px !important;
  }
  .closeIcon {
    margin-left: auto;
    margin-top: -22px;
    cursor: pointer;
  }
  .detailText {
    font-weight: 300;
    font-size: 20px;
    line-height: 146%;
  }
}
.show_modal {
  position: absolute;
  // position: fixed;
  top: 72px;
  right: 0px;
  border-radius: 6px;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  // opacity: 1;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  // transition: opacity .5s ease-in;
  @media only screen and (min-width: 320px) and (max-width: 1024px) {
    // top: 48px;
  }
  @media only screen and (min-width: 600px) and (max-width: 800px) {
    top: 48px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    top: 86px;
  }
}
.show_modal {
  -webkit-animation: 1s ease 0s normal forwards 1 slide-right-pause;
  animation: 1s ease 0s normal forwards 1 slide-right-pause;
}
@keyframes slide-right-pause {
  0% {
    right: -400px;
  }
  100% {
    right: 5px;
  }
}
.close_model {
  -webkit-animation: 4s ease 0s normal forwards 1 slide-right-close;
  animation: 4s ease 0s normal forwards 1 slide-right-close;
}
@keyframes slide-right-close {
  0% {
    right: 5px;
  }
  100% {
    right: -400px;
  }
}
// popup overlay
.popup-backdrop.show {
  opacity: 0.5;
}
.popup-backdrop.fade {
  opacity: 0;
}
.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
